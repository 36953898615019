.modal-container {
    width: calc(100vw - 250px) !important;
    top: 5px;
    margin-right: 0;
    margin-top: 0;
    padding: 0;
}

@media only screen and (max-width: 992px) {
    .modal-container {
        width: 100vw !important;
        margin: auto;
    }
}