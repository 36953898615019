.search-btn {
  margin: 0 5px 3px;
  padding: 4.7px 15px;
}

.btn-remove {
  font-size: 1rem;
  padding: 0;
  line-height: 1;
  color: red;
}
